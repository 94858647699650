import React from "react";
// import DangZhengJiGuan from 'src/assets/images/dangzhengjiguan.png'
// import JiuCuo from 'src/assets/images/jiucuo.png'
// import BeiAn from 'src/assets/images/beian.png'
// import QR from 'src/assets/images/qr.png'
import "./index.less";

const Footer = () => {
  return (
    <div className="footer text-white w-full">
      <div className="footer-wrap">
        {/* <div className="flex items-center justify-start">
          <img className="gov-logo" src={DangZhengJiGuan} alt="党政机关"/>
          <img className="gov-logo" src={JiuCuo} alt="政府网站纠错"/>
        </div> */}
        {/* <div className="footer-content flex items-center">
          <div className="footer-content-left">
            <div className="tips-con flex items-center justify-between flex-wrap">
              <a>联系我们</a>
              <a>关于我们</a>
              <a>站点地图</a>
              <a>法律声明</a>
              <a>建议意见</a>
            </div>
          </div>
          <div className="footer-content-right">
            <div className="tips-con flex flex-col">
              <span>咨询电话：59865251</span>
              <span>信访电话：59865050</span>
              <span>地址：北京市朝阳区惠新东街6号</span>
            </div>
          </div>
        </div> */}
        <div className="flex flex-col items-center justify-center  gap-3 h-full">
          <div className="flex items-center justify-center">
            <div className="cy-1">Copyright @ 2023 北京市创业培训学校</div>
            <div className="cy-2" style={{marginLeft: '15px'}}> 技术支持：赢谷创客:全栈道
            </div>
          </div>
          <div className="flex items-center">
            <a style={{color: '#333',fontSize:'13px'}} href="https://beian.miit.gov.cn/">京ICP备2021024304号</a>
          </div>
        </div>

        {/* <div className="flex justify-end">
          <div className="flex flex-col items-center justify-center">
            <img className="qr" src={QR} width="52" height="52" alt="微信二维码"/>
            <div className="mt-2 text-xs">扫一扫关注官方微信公众号</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
