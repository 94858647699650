import React from "react";
import Logo from "src/assets/images/logo.png";
import "./index.less";

const Header = () => {
  return (
    <div className="login-header w-full">
      <div className="bd flex items-center" >
        <div className="flex items-center">
          <img className="brand-img" src={Logo} alt="赢之营创新研究院" />
          <div className="brand-info">
            <p className="tip-1">
              {/* <div style={{fontSize: '19px'}}>赢加Saas 数字化服务</div>
            <div style={{fontSize: '12px'}}>科技赋能数字变革，让业务更高效</div> */}
            </p>
            <p className="devide-line"></p>
            <p className="tip-2">后台管理系统</p>
          </div>
        </div>
        <div className="info">矢志创新创业&nbsp;&nbsp;&nbsp;创造美好生活</div>
      </div>
    </div>
  );
};

export default Header;
